@import url(./fonts/ardarling.scss);
/*
 *
 *   EGRET - Angular Material Design Admin Template
 *   version 1.0.0
 *
*/

/*
# Table of contents
## Globals
## Common
## Loader
## Third pirty adjust
    Scroll Bar
    Data table
## Top Bar
## Main Sidenav
## Collapsed Sidenav
## Session
    Sign in
    sign up
    forgot
    lockscreen
    Not found
## Forms
## Inbox
## Calendar
## Chats
## Gallery
## Plans & Pricings
## Users
## Profile
## Media queries
*/

/*---- ngx-datatable CSS import -----*/
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";

/*---- Globals -----*/
html {
    font-size: 16px;
    scroll-behavior: smooth;
}
html,
body {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    min-height: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.5;
    font-family: "Roboto", Helvetica Neue, sans-serif;
}
div {
    box-sizing: border-box;
}
.mat-card {
    font-size: 0.875rem;
}
img {
    max-width: 100%;
}
a,
a:focus,
a:hover {
    text-decoration: none;
}
a {
    color: inherit;
}
p {
    margin: 0 0 16px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.1;
    color: inherit;
}
.h1,
h1 {
    font-size: 2rem;
}
.h2,
h2 {
    font-size: 1.75rem;
}
.h3,
h3 {
    font-size: 1.5rem;
}
.h4,
h4 {
    font-size: 1.25rem;
}
.h5,
h5 {
    font-size: 1rem;
}
.h6,
h6 {
    font-size: 0.875rem;
}
code {
    padding: 8px;
    background: rgba(0, 0, 0, 0.08);
}
/*---- Common -----*/
.container {
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
}
.bg-none {
    background: transparent !important;
}
.bg-white {
    background: #ffffff !important;
}
.m-0 {
    margin: 0 !important;
}
.m-1 {
    margin: 1rem !important;
}
.mt-1 {
    margin-top: 1rem !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mr-1 {
    margin-right: 1rem !important;
}
.mr-0 {
    margin-right: 0 !important;
}
.mb-1 {
    margin-bottom: 1rem !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.ml-1 {
    margin-left: 1rem !important;
}
.ml-0 {
    margin-left: 0 !important;
}
.mb-05 {
    margin-bottom: 0.5rem !important;
}
.margin-333 {
    margin: 0.333333rem !important;
}
.pt-1 {
    padding-top: 1rem !important;
}
.pt-0 {
    padding-top: 0 !important;
}
.pr-1 {
    padding-right: 1rem !important;
}
.pr-0 {
    padding-right: 0 !important;
}
.pb-1 {
    padding-bottom: 1rem !important;
}
.pb-0 {
    padding-bottom: 0 !important;
}
.pl-1 {
    padding-left: 1rem !important;
}
.pl-0 {
    padding-left: 0 !important;
}
.p-0 {
    padding: 0 !important;
}
.p-1 {
    padding: 1rem !important;
}
.p-05 {
    padding: 0.5rem !important;
}

.height-100 {
    min-height: 100vh;
}
.fix {
    position: relative;
    overflow: hidden;
}
.mat-box-shadow {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.light-gray {
    background: rgba(0, 0, 0, 0.024);
}
.light-mat-gray {
    background: rgba(0, 0, 0, 0.08);
}
.mat-blue {
    background: #247ba0;
    color: #fefefe;
}
.mat-red {
    background-color: #f44336 !important;
    color: #fff !important;
}
.mat-indigo {
    background-color: #3f51b5 !important;
    color: #fff !important;
}
.mat-brown {
    background-color: #785548 !important;
    color: #fefefe;
}
.mat-teal {
    background-color: #009688 !important;
    color: #fff !important;
}
.mat-purple {
    background-color: #9c27b0 !important;
    color: hsla(0, 0%, 100%, 0.87) !important;
}

.fz-1 {
    font-size: 1rem !important;
}
.fz-2 {
    font-size: 2rem !important;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.font-light {
    font-weight: 300;
}
.font-normal {
    font-weight: normal !important;
}
.text-muted {
    color: rgba(0, 0, 0, 0.54) !important;
}
.text-muted-white {
    color: rgba(255, 255, 255, 0.54) !important;
}
.text-gray {
    color: rgba(0, 0, 0, 0.7) !important;
}
.text-sm {
    font-size: 0.813rem;
}
.list-item-active {
    border-left: 3px solid;
}
.material-icons.icon-sm {
    font-size: 18px !important;
    line-height: 18px !important;
    height: 18px;
    width: 18px;
}
.material-icons.icon-xs {
    font-size: 13px !important;
    line-height: 13px;
    height: 13px;
    width: 13px;
}

.mat-card.default {
    padding: 0;
}
.mat-card.default .mat-card-title {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 1;
    font-weight: 400;
}
.mat-card.default > :first-child {
    border-radius: 2px 2px 0 0;
}
.mat-card.default .mat-card-subtitle:first-child {
    padding-top: 1rem;
}
.mat-card.default .mat-card-subtitle {
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 1;
}
.mat-card.default .mat-card-content {
    padding: 1rem;
    margin-bottom: 0;
    position: relative;
}
.mat-card.default .mat-card-actions,
.mat-card.default .mat-card-actions:last-child {
    padding: 0.5rem;
    margin: 0;
}
.mat-card.default > :last-child {
    border-radius: 0 0 2px 2px;
}

.mat-card {
    margin: 0.333333rem;
    overflow: hidden;
}
.mat-card .mat-card-title .mat-divider,
.mat-divider.full-width {
    margin-left: -24px;
    margin-right: -24px;
}

.mat-card.p-0 .mat-card-title .card-title-text {
    padding: 1rem 1.5rem;
}
.mat-card.p-0 .mat-card-title .card-title-text .card-control {
    height: 24px;
    width: 24px;
    line-height: 24px;
}
.mat-card.p-0 .mat-card-title .card-title-text .mat-card-subtitle {
    margin: 0;
}
.mat-card.p-0 .mat-card-title .mat-divider {
    margin-left: 0;
    margin-right: 0;
    border-top-color: rgba(0, 0, 0, 0.04);
}
.mat-card.p-0 .mat-card-image {
    margin: 0 0 1rem !important;
}
.mat-card.p-0 .mat-card-content {
    padding: 0 1.5rem 1.5rem;
}
.mat-button,
.mat-raised-button {
    font-weight: 400 !important;
}
[mat-lg-button] {
    padding: 0 32px !important;
    font-size: 18px;
    line-height: 56px !important;
}
.mat-icon-button[mat-sm-button] {
    height: 24px;
    width: 24px;
    line-height: 24px;
}

.mat-icon-button[mat-xs-button] {
    height: 20px;
    width: 20px;
    line-height: 20px;
}
.mat-icon-button[mat-xs-button] .mat-icon {
    font-size: 16px;
    line-height: 20px !important;
    height: 20px;
    width: 20px;
}
.mat-chip[mat-sm-chip] {
    padding: 4px 6px 4px 6px !important;
    border-radius: 4px !important;
    font-size: 12px !important;
    line-height: 12px !important;
    max-height: 20px;
    box-sizing: border-box;
}
.mat-icon-button.img-button img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}
.compact-list .mat-list-item {
    line-height: 1.1;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.compact-list .mat-list-item:hover {
    background: rgba(0, 0, 0, 0.08);
    cursor: pointer;
}
.list-tasktype .tasktype-item {
    padding: 12px;
}
.list-tasktype .tasktype-item:hover {
    background: rgba(0, 0, 0, 0.08);
}
.list-tasktype .tasktype-item .tasktype-action {
    visibility: hidden;
}
.list-tasktype .tasktype-item:hover .tasktype-action {
    visibility: visible;
}
.doughnut-grid {
    border-radius: 2px;
    overflow: hidden;
}
.doughnut-grid .doughnut-grid-item {
    padding: 1rem;
}
.doughnut-grid .doughnut-grid-item p {
    margin: 0;
}
.doughnut-grid .doughnut-grid-item .chart {
    margin: 0 0 8px;
}

.toolbar-avatar {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    line-height: 24px;
}
.toolbar-avatar > img {
    width: 40px !important;
    border-radius: 50%;
}
.toolbar-avatar.md,
.toolbar-avatar.md > img {
    width: 48px !important;
    height: 48px;
}
.toolbar-avatar > .status-dot {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 25px;
    right: -2px;
    border: 2px solid #ffffff;
    background: #ccc;
}
.full-width {
    width: 100% !important;
}
.dnd-item {
    cursor: move;
    cursor: grab;
    cursor: -webkit-grab;
}
.icon-circle {
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.6rem;
    border-radius: 50%;
}
.mat-drawer .mat-list-item:hover {
    background: rgba(0, 0, 0, 0.035) !important;
}

.fixed-topbar .rightside-content-hold {
    overflow: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 64px;
}
.fixed-topbar-breadcrumb .rightside-content-hold {
    overflow: auto;
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
    top: 104px;
}

.rightside-content-hold {
    padding: 0.333333rem;
    overflow-x: hidden;
    position: relative;
    min-height: 450px;
}
.text-small {
    font-size: 0.813rem;
}
.text-small .mat-icon {
    font-size: 1rem;
    vertical-align: sub;
    margin: 0 2px;
}
.mat-card-title {
    font-size: 1rem !important;
}
.icon-chip {
    font-size: 11px !important;
    padding: 4px 8px !important;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.icon-chip .mat-icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
    margin-right: 4px;
}
.mat-chip:not(.mat-basic-chip) {
    display: inline-block;
    padding: 8px 12px 8px 12px;
    border-radius: 24px;
    font-size: 13px;
    line-height: 16px;
}
.ql-container .ql-editor {
    min-height: 200px;
}
.chart {
    display: block;
    width: 100%;
}
.form-error-msg {
    color: #f44336;
    display: block;
    padding: 5px 0;
}
.accordion-handle {
    cursor: pointer;
}
.app-accordion {
    max-height: 200px;
    transition: max-height 0.3s ease;
}
.app-accordion.open {
    max-height: 10000px;
}
.app-accordion .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}
.app-accordion.open .accordion-content {
    max-height: 800px;
}
.app-accordion.open .hidden-on-open {
    display: none !important;
}
.app-accordion:not(.open) .show-on-open {
    display: none !important;
}

/*---- Loader ----*/
.app-loader,
.view-loader {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: auto;
}
.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}
.double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.view-loader {
    display: block;
    padding-top: 160px;
    background: rgba(255, 255, 255, 0.3);
    z-index: 9999;
}
.view-loader * {
    margin: auto;
}
@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}
@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

/*---- Third pirty adjust -----*/
/*--------- Material ---------*/
mat-list a[mat-list-item] .mat-list-item-content,
mat-list mat-list-item .mat-list-item-content,
mat-nav-list a[mat-list-item] .mat-list-item-content,
mat-nav-list mat-list-item .mat-list-item-content,
[mat-menu-item],
.mat-option,
body .mat-select-trigger {
    font-size: 0.875rem !important;
}
/*------- quill rich text editor ----------*/
.p-0.mat-card-content .ql-container {
    border: 0 !important;
}
.p-0.mat-card-content .ql-toolbar.ql-snow {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
/*--- Scroll Bar ---*/
.ps__scrollbar-y-rail {
    z-index: 999;
}
.collapsed-menu .ps__scrollbar-y-rail {
    z-index: auto;
}

/*--- Data table ---*/
.ngx-datatable.material {
    box-shadow: none !important;
}

/*------ Map ------*/
.agm-info-window-content {
    color: rgba(0, 0, 0, 0.87);
}
/*-------- Chart js ---------*/
.chart {
    margin-left: -10px;
}

/*-------- Hopscotch Tour ---------*/
div.hopscotch-bubble,
div.hopscotch-bubble .hopscotch-title,
div.hopscotch-bubble .hopscotch-content,
div.hopscotch-bubble .hopscotch-nav-button {
    font-family: "Roboto", Helvetica Neue, sans-serif !important;
}
div.hopscotch-bubble {
    border: 1px solid;
    border-radius: 2px;
}
div.hopscotch-bubble .hopscotch-bubble-number {
    border-radius: 50%;
    box-sizing: border-box;
    padding: 0;
    font-weight: 500;
    height: 32px;
    width: 32px;
    line-height: 32px;
    background: transparent;
    color: rgba(0, 0, 0, 0.87);
}
div.hopscotch-bubble .hopscotch-title {
    font-size: 17px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0.1px;
}
div.hopscotch-bubble .hopscotch-content {
    margin: -5px 0 16px;
}
div.hopscotch-bubble .hopscotch-bubble-close {
    background: url("../images/cancel.png");
    background-size: 8px;
    background-position: 8px 8px;
    background-repeat: no-repeat;
    cursor: pointer;
}
div.hopscotch-bubble .hopscotch-bubble-close:active {
    outline: 0;
}
/* up arrow  */
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up {
    top: -17px;
}
/* right arrow */
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right {
    right: -34px;
}
/* bottom arrow */
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down {
    bottom: -34px;
}
/* Left Arrow */
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left {
    left: -17px;
}

div.hopscotch-bubble .hopscotch-nav-button {
    border: 0;
    border-radius: 2px;
    font-weight: normal;
    text-shadow: none !important;
    padding: 0 18px;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    background-image: none !important;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transform: translate3d(0, 0, 0);
    transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

div.hopscotch-bubble .hopscotch-nav-button:hover,
div.hopscotch-bubble .hopscotch-nav-button:active,
div.hopscotch-bubble .hopscotch-nav-button:focus {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
    outline: none;
}
div.hopscotch-bubble .hopscotch-nav-button.prev,
div.hopscotch-bubble .hopscotch-nav-button.prev:hover {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
}

/*---- Top Bar -----*/
.topbar.mat-toolbar {
    position: relative;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    z-index: 999;
}
.topbar .mat-select-value {
    font-weight: 400;
    color: #ffffff;
}

.toggle-collapsed {
    transform: rotate(0deg);
    -webkit-transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.collapsed-menu .toggle-collapsed {
    transform: rotate(180deg);
}
.search-bar .top-search-form {
    position: relative;
    background: #fff;
    border-radius: 2px;
    margin-right: 1rem;
    display: block;
    max-width: 800px;
    margin-left: 20px;
}
.search-bar .top-search-form .material-icons {
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -12px;
    color: rgba(0, 0, 0, 0.87);
}
.search-bar .top-search-form input {
    font-size: 1rem;
    padding: 0.95rem 0.75rem;
    z-index: 2;
    cursor: text;
    text-indent: 30px;
    border: none;
    background: transparent;
    width: 100%;
    outline: 0;
}
#langToggle .mat-select-trigger {
    width: 80px;
    min-width: 80px;
}
.theme-list .mat-menu-item {
    width: 48px;
    height: 48px;
    padding: 5px;
}
.theme-list .mat-menu-item .egret-swatch {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}
.theme-list .mat-menu-item .active-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: #ffffff;
}

.topbar-button-right {
    margin-left: 1rem !important;
}


/*---- Main Sidenav -----*/
.branding {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: fixed;
    width: 100%;
    max-width: 15rem;
    height: 64px;
    line-height: 64px;
    top: 0;
    left: 0;
    z-index: 9999;
    color: #444;
}
.logo-group {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.branding .app-logo {
    width: auto;
    height: 22px;
    margin-right: 8px;
}
.branding .app-logo-text {
    width: auto;
    /*height: 20px; - DH*/
}
.app-user {
    text-align: center;
    width: 100%;
    padding: 1rem 0 0.4rem;
}
.app-user .app-user-photo {
    width: 72px;
    margin: 0 auto 8px;
    height: 72px;
    box-sizing: border-box;
    border: 1px solid rgba(120, 130, 140, 0.13);
    padding: 4px;
    border-radius: 50%;
}
.app-user img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.app-user .app-user-name {
    display: block;
    font-size: 0.875rem;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.96);
    margin-left: -2px;
}
.sidebar-band {
    position: fixed;
    top: 64px;
    left: 0;
    display: block;
    width: 100%;
    z-index: 100;
}
.icon-menu {
    padding: 0 24px;
}
.icon-menu .icon-menu-item {
    display: inline-block;
}
.icon-menu .icon-menu-item button {
    min-width: auto;
    margin: 4px;
}
.icon-menu > .mat-divider {
    margin: 1rem 0;
}
.app-side-nav-container {
    height: 100vh;
}
.sidebar-panel.mat-drawer {
    width: 15rem;
    min-width: 15rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.sidebar-panel.mat-drawer .navigation-hold {
    position: relative;
    padding-top: 64px;
    background: rgba(255, 255, 255, 0.95);
    min-height: 100%;
    -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.nav-section-title {
    padding: 16px 0 0 16px;
    display: block;
    font-size: 12px;
}
.sidebar-panel.mat-drawer .mat-nav-list .sidebar-list-item .mat-list-item-content {
    font-size: 0.875rem !important;
    display: block;
    overflow: hidden;
    height: auto;
    min-height: 100%;
    padding: 0;
    width: 100%;
    -webkit-transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
}
.mat-nav-list .mat-list-item-content.mat-list-item-focus,
.mat-nav-list .mat-list-item-content:hover {
    background: transparent;
}
.sidebar-list-item.open > .mat-list-item-content {
    background: rgba(0, 0, 0, 0.03);
}
.sidebar-panel .mat-nav-list a {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 48px;
    padding: 0 16px 0 0;
    z-index: 99;
}
.sidebar-panel.mat-drawer .sidebar-list-item mat-icon:not(.menu-caret) {
    text-align: center;
    margin-right: 3px;
    height: 48px;
    width: 48px;
    line-height: 48px;
    border-left: 3px solid;
    border-color: transparent;
}
.sidebar-panel.mat-drawer .sidebar-list-item mat-icon.menu-caret {
    float: right;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-panel.mat-drawer .sidebar-list-item.open mat-icon.menu-caret {
    float: right;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.sidebar-panel.mat-drawer .mat-list-item-content > a {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
}
.sidebar-panel.mat-drawer .sidebar-list-item {
    max-height: 48px;
    overflow: hidden;
    -webkit-transition: all 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    transition: all 0.4s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-panel.mat-drawer .sidebar-list-item.open {
    max-height: 10000px;
    overflow: visible;
}
.sidebar-panel.mat-drawer .sidebar-list-item.open > .mat-list-item-content > .sub-menu {
    max-height: 10000px;
}
.sidebar-panel.mat-drawer .sidebar-list-item .sub-menu {
    padding: 0;
    -webkit-transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    border-left: 3px solid transparent;
}
.sidebar-panel.mat-drawer .sidebar-list-item .sub-menu .mat-list-item {
    -webkit-transition: height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    transition: height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-panel.mat-drawer .sidebar-list-item .sub-menu .mat-list-item.selected {
    background: rgba(0, 0, 0, 0.05);
}

.sidebar-panel.mat-drawer .sub-menu a {
    padding-left: 54px;
}
.app-side-nav-container .mat-drawer-content {
    padding: 0;
}

/*------- Collapsed Sidenav -------*/
.collapsed-menu .sidebar-panel {
    width: 48px;
    min-width: 48px;
    overflow: visible !important;
}
.collapsed-menu .sidebar-panel .navigation-hold,
.collapsed-menu .sidebar-panel .sidebar-list-item > .mat-list-item-content {
    overflow: visible !important;
}
.collapsed-menu .sidebar-panel .icon-menu {
    padding: 0;
}
.collapsed-menu .icon-menu .icon-menu-item button {
    margin: 0 0 4px 0;
    padding: 0 12px;
}
.collapsed-menu .sidebar-panel .sidebar-list-item > .mat-list-item-content > a {
    position: relative;
    padding: 0;
}
.collapsed-menu .sidebar-panel .sidebar-list-item > .mat-list-item-content > a .menu-item-tooltip {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 48px;
    width: 48px;
    background: transparent;
}
.collapsed-menu .sidebar-panel .branding {
    max-width: 48px;
}
.collapsed-menu .sidebar-panel .branding .app-logo-text {
    display: none;
}
.collapsed-menu .sidebar-panel .branding .app-logo {
    margin: 0;
}
.collapsed-menu .sidebar-panel .sidebar-list-item.open .sub-menu {
    position: absolute;
    left: 47px;
    top: 0;
    width: 200px;
    z-index: 9999;
    display: block;
    background: #fff;
    max-height: 200px !important;
    overflow-y: scroll;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.collapsed-menu .sidebar-panel .sidebar-list-item.open .sub-menu .mat-list-item a {
    padding-left: 1rem;
}
.collapsed-menu .sidebar-panel .mat-nav-list > div > .sidebar-list-item a .mat-icon {
    margin: 0;
}
.collapsed-menu .sidebar-panel .mat-nav-list > div > .sidebar-list-item a span:not(.menu-item-tooltip),
.collapsed-menu .sidebar-panel .mat-nav-list > div > .sidebar-list-item a .menu-caret,
.collapsed-menu .sidebar-panel .nav-section-title,
.collapsed-menu .sidebar-panel .icon-menu-separator,
.collapsed-menu .sidebar-panel .app-user,
.collapsed-menu .sidebar-panel .sub-menu {
    display: none;
}

.collapsed-menu .app-side-nav-container > .mat-drawer-content {
    margin-left: 48px !important;
}
.collapsed-menu #sidenavToggle {
    display: none;
}

/*---- Session (Sign in, sign up, forgot, lockscreen) -----*/
.page-wrap {
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
}
.session-progress {
    position: relative;
    bottom: -5px;
    z-index: 9999;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
.session-form-hold > .mat-card {
    margin: 0;
}
.session-lockscreen {
    max-width: 320px;
}
.lockscreen-user {
    text-align: center;
    margin-bottom: 1rem;
    display: flow-root;
    overflow: hidden;
}
.lockscreen-face {
    max-width: 72px;
    max-height: 72px;
    border-radius: 50%;
    margin-bottom: 0.5rem;
}
.app-error {
    text-align: center;
    width: 320px;
    max-width: 320px;
    margin: 0 auto;
}
.app-error .error-icon {
    height: 120px;
    width: 120px;
    font-size: 120px;
    float: left;
}
.app-error .error-text {
    float: right;
    width: 200px;
    max-width: 200px;
}
.app-error .error-title {
    font-size: 5rem;
    font-weight: 900;
    margin: 0;
}
.app-error .error-subtitle {
    font-size: 1.5rem;
    font-weight: 300;
}
.app-error .error-actions {
    width: 100%;
    overflow: hidden;
    min-height: 54px;
    margin-top: 100px;
}

/*---- Forms -----*/
.fileupload-drop-zone {
    text-align: center;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    padding: 48px;
    height: 120px;
}
.default-table {
    text-align: left;
}
.default-table > thead tr th {
    font-weight: 400;
    padding: 0.9rem 1.2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.default-table tbody tr td {
    padding: 0.9rem 1.2rem;
}

/*---- Inbox -----*/
.inbox-sidenav {
    width: 15rem;
    padding-right: 0.333rem;
    background: transparent !important;
}
.inbox-sidenav .inbox-nav-list .mat-list-item-content > a > span {
    font-size: 0.875rem;
}

.inbox-sidenav .mat-list-item-content .mat-icon {
    margin-right: 8px;
}
.inbox-toolbar {
    min-height: 56px !important;
    margin-bottom: 0.333rem;
}
.inbox-toolbar .mat-toolbar-row {
    height: 56px !important;
}
.inbox-toolbar .mat-toolbar-row .inbox-toggle-all {
    display: flex;
    align-items: center;
}
.inbox-toolbar .mat-toolbar-row .mat-checkbox-frame {
    border-color: #ffffff;
}
.messages-wrap {
    min-height: 800px;
}
.messages.mat-list,
.messages .mat-list-item-content {
    padding: 0 !important;
    font-size: 0.875rem !important;
}
.messages-wrap .inbox-hold {
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-right: 1px;
    background: #ffffff;
}
.messages-wrap .inbox-hold .message-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.messages-wrap .inbox-hold .message-item:last-child {
    border: 0;
}
.message-item .message-head {
    padding: 0.5rem 0.54rem;
}
.message-item.open .message-head {
    padding: 0.5rem 1.5rem;
}
.message-item.open .message-head {
    background: rgb(250, 250, 250);
}
.message-item .mat-list-item-content {
    display: block !important;
    height: auto !important;
}
.message-item .mat-list-item-content .mail-check {
    padding: 0 0.5rem;
}
.message-item .mat-list-item-content p.accordion-handle {
    margin: 0;
}
.inbox-face {
    height: 48px;
    width: 48px;
    border-radius: 50%;
}
.message-item .message-text {
    padding: 1.5rem;
    overflow: hidden;
}

/*---- Calendar -----*/
.card-title-text.calendar-title {
    padding: 0.5rem 1.5rem !important;
}
.cal-top-col {
    width: 33.3333%;
    float: left;
}
.cal-event-action .material-icons {
    vertical-align: sub;
    margin: 6px;
}
.cal-open-day-events {
    box-shadow: none !important;
}

/*---- Chats -----*/
.chat-sidenav {
    width: 15rem;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.chat-sidebar-toolbar,
.chat-toolbar,
.chat-sidebar-toolbar .mat-toolbar-row,
.chat-toolbar .mat-toolbar-row {
    min-height: 56px !important;
    height: 56px !important;
}
.chats-wrap .conversations-hold .single-conversation {
    overflow: hidden;
    padding: 0 2rem;
    margin-bottom: 2rem;
}
.chats-wrap .conversations-hold .single-conversation .toolbar-avatar {
    vertical-align: bottom;
    margin-right: 1rem;
}
.chats-wrap .conversations-hold .single-conversation .chat-username {
    margin: 0 0 0.625rem;
    font-size: 0.875rem;
}
.chats-wrap .conversations-hold .single-conversation .conversation-msg {
    padding: 0.48rem 0.94rem;
    display: inline-block;
    border-radius: 4px;
}
.chats-wrap .conversations-hold .single-conversation.sender .conversation-msg {
    background: #ffffff;
}

.chats-wrap .conversations-hold .single-conversation .chat-date {
    font-size: 11px;
    padding: 2px;
    margin: 0;
    clear: both;
}
.chats-wrap .chat-input-actions {
    padding: 0.6rem;
}

/*---- Gallery -----*/
.app-gallery .gallery-control-wrap {
    position: absolute;
    width: 100%;
    height: 40px;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.35);
    /*
    note - the fade in stopped working to Angular & Material 13
    opacity: 0;
    transition: opacity 0.3s ease-in;
    -webkit-transition: opacity 0.3s ease-in;
    */
}
.app-gallery img {
    max-width: 150%; /*DH*/
    object-fit: cover; /*DH*/
}
.app-gallery .mat-figure:hover .gallery-control-wrap {
    opacity: 1;
}
.app-gallery .mat-figure .gallery-control-wrap .gallery-control {
    padding: 0 0 0 1rem;
}
.app-gallery .mat-figure .gallery-control-wrap .gallery-control .photo-detail {
    margin: 0;
    color: #fefefe;
    line-height: 40px;
}
.app-gallery .mat-figure .gallery-control-wrap .gallery-control .mat-icon {
    color: #fff;
}

/*---- Plans & Pricings -----*/
.plan-pricing .mat-list-item .mat-list-item-content {
    display: inline-flex !important;
    text-align: center;
    font-size: 0.875rem !important;
}

/*---- Users -----*/
.user-card .card-title-text {
    padding: 0.5rem 1.5rem !important;
    align-items: center;
}
.user-card .user-details > p:last-child {
    margin-bottom: 0;
}
.user-card .user-details .mat-icon {
    vertical-align: top;
    margin-right: 0.5rem;
}
/*------- Profile ------*/
.profile-sidebar {
    box-sizing: border-box;
}
.profile-sidebar .propic {
    width: 100%;
    margin-bottom: 6px;
}
.profile-sidebar .propic img {
    width: 50%;
    height: auto;
    border-radius: 50%;
}
.profile-sidebar .profile-title .main-title {
    font-size: 1.5rem;
}
.profile-sidebar .profile-nav {
    margin-left: -24px;
    margin-right: -24px;
}
.profile-sidebar .profile-nav .mat-list-item .mat-icon {
    margin-right: 8px;
}

.timeline {
    position: relative;
    overflow: hidden;
}
.timeline::before {
    content: "";
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 24px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.12);
}
.timeline .timeline-item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 24px;
}
.timeline .timeline-badge {
    float: left;
    position: relative;
    margin-right: 30px;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    overflow: hidden;
}
.timeline .timeline-badge img {
    max-width: 100%;
}
.timeline .timeline-badge .icon-badge {
    text-align: center;
    width: 48px;
    height: 48px;
    line-height: 48px;
}
.timeline .timeline-body-top {
    margin-bottom: 8px;
}
.timeline .timeline-body {
    float: right;
    width: calc(100% - 78px);
}
.timeline .timeline-body-content img {
    border-radius: 4px;
    max-width: 100%;
}
.timeline .timeline-body-content > :last-child {
    margin-bottom: 8px;
}

/*------- Home -------*/
#home-header > .container {
    height: 100px;
}
.hometop-btn {
    line-height: 100px !important;
}
.home-intro-section {
    background: url("../images/home-bg.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 100px 0;
    color: #ffffff;
    overflow: hidden;
}
.home-intro-title {
    margin: 0 0 40px;
    font-size: 48px;
    font-weight: 900;
    line-height: 54px;
}
.home-intro-desc {
    margin: 0 0 40px;
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
}
.intro-list {
    margin: 0 0 40px;
}
.intro-product {
    position: relative;
    top: 100px;
    left: 60px;
}
.intro-product-link {
    position: absolute;
    top: -110px;
    left: -45px;
    text-align: center;
    display: block;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
    padding: 37px 20px;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 14px 32px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}
.intro-product-link:hover {
    box-shadow: 0 19px 32px rgba(0, 0, 0, 0.4);
}
.intro-product-link .price {
    font-size: 32px;
    font-weight: 700;
}
.intro-product-link .price-text {
    font-size: 14px;
}

.home-section {
    padding: 80px 0;
}
.home-section mat-card:hover {
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
}
.section-header {
    margin: 0 0 2rem;
}
.section-header h2 {
    font-size: 32px;
    font-weight: 700;
    margin: 0 0 16px;
}
.section-header p {
    font-size: 16px;
    max-width: 46rem;
    margin: 0;
}
.home-section-action {
    padding: 2.5rem 0 0;
}
[mat-card-icon] {
    font-size: 64px !important;
    height: 64px !important;
    width: 64px !important;
    transition: all 0.3s ease;
}
.home-fancy-card {
    border-top: 2px solid #212121;
    transition: all 0.3s ease;
}

.home-fancy-card:hover {
    border-top: 2px solid #3f51b5;
}
.home-fancy-card:hover [mat-card-icon] {
    color: #3f51b5;
}
.home-fancy-card .description {
    font-size: 15px;
    color: #616161;
}

.home-cta {
    background: url("../images/home-bg-cta.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #ffd97d;
}

.home-opensource {
    background: url("../images/home-bg-opensource.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #fff;
    padding: 40px;
}

.home-footer-top {
    background-color: #212121;
    color: #ffffff;
}
.footer-box {
    padding: 30px;
    box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.2);
}
.footer-box-title {
    position: relative;
    margin: 0 0 24px;
    padding-bottom: 12px;
}
.footer-box-title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 64px;
    background-color: #ffc107;
}
.footer-box .mat-list {
    padding: 0 !important;
}
.footer-box .mat-list-item-content {
    padding-left: 0 !important;
}
.footer-box .mat-list-text {
    color: #ffffff;
}
.footer-box a {
    color:#ffd97d;
    cursor: pointer;
}

.socials ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.socials ul li {
    display: inline-block;
}
.social-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 8px;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
}
.social-icon.facebook {
    background: url("../images/social-facebook.png") no-repeat center center;
}
.social-icon.twitter {
    background: url("../images/social-twitter.png") no-repeat center center;
}
.social-icon.linkedin {
    background: url("../images/social-linkedin.png") no-repeat center center;
}
.social-icon.dribble {
    background: url("../images/social-dribble.png") no-repeat center center;
}
/*---- Media queries -----*/
@media (max-width: 767px) {
    .mat-card-title .mat-divider {
        margin-left: 0;
        margin-right: 0;
    }
    .cal-top-col {
        width: 100%;
    }
    .accordion-handle {
        flex-direction: column !important;
    }
    .app-error .error-icon {
        height: 100px;
        width: 100px;
        font-size: 100px;
    }
    .intro-product {
        top: 0;
        left: 20px;
    }
    .intro-product-link {
        top: -80px;
        transform: scale(0.5);
    }
}

@media (max-width: 959px) {
    .inbox-sidenav {
        background: inherit !important;
    }
    .footer-box {
        box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.2);
    }
}

/* Dert Of Derts */
.dod-dialog-panel {
    .mat-dialog-title {
        font-family: ARDarling, Courier;
        font-weight: normal;
        font-style: normal;
        font-size: 1.5rem;
        margin-top: 0px;
    }

    .mat-dialog-content {
        font-family: "Kalam", cursive;
        font-size: 1rem;
    }

    .mat-dialog-container {
        border: 3px solid #000;
        border-radius: 15px 130px 15px 130px / 130px 15px 130px 15px;

        h2 {
            font-family: ARDarling, Courier;
            font-weight: normal;
            font-style: normal;
            font-size: 1.8rem !important;
        }

        .mat-raised-button.mat-primary {
            font-family: "Kalam", cursive;
            font-size: 1rem;
            border: 3px solid #000;
            border-radius: 255px 15px 255px 15px / 15px 255px 15px 255px;
        }

        .mat-raised-button.mat-primary:hover {
            border: 3px solid #000;
            border-radius: 15px 255px 15px 255px / 255px 15px 255px 15px;
        }

        .mat-raised-button.mat-button-base {
            font-family: "Kalam", cursive;
            font-size: 1rem;
            border: 3px solid #000;
            border-radius: 255px 15px 255px 15px / 15px 255px 15px 255px;
        }

        .mat-raised-button.mat-button-base:hover {
            border: 3px solid #000;
            border-radius: 15px 255px 15px 255px / 255px 15px 255px 15px;
        }
    }

    mat-vertical-stepper {
        .mat-step-icon-selected {
            color: #8fbe36;
        }
    }


}

.table-container {
    margin:5px;
    background-color: #fff;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.08), 0 2px 2px 0 rgba(0,0,0,.05), 0 1px 5px 0 rgba(0,0,0,.05);
}
